<script>
import SafetyTopicNewView from './TopicSafetyNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'SafetyTopicEditView',
  extends: SafetyTopicNewView,
  methods: {
    getSafetyTopic () {
      this.$store.dispatch('safetyTopic/fetchOne', this.$route.params.id)
        .then(() => {
          this.safetyTopic = this.$store.getters['safetyTopic/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('safetyTopic/update', this.safetyTopic)
        .then(() => {
          this.safetyTopic = this.$store.getters['safetyTopic/detail']
          if (this.$store.getters['safetyTopic/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['safetyTopic/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getSafetyTopic()
  }
}
</script>
